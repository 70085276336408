import * as Yup from "yup";

export const ValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    password: Yup.string()
        .min(6, "Password should be of minimum 6 characters length")
        .required("Password is required"),
});