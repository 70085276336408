import { Box, styled } from "@mui/material";
import { FC, useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSideBar";
import { ModalProvider } from "../../contexts/ModalDialogContext";

// styled components
const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: `calc(100%)`,
  margin: 10,
  marginLeft: "18%", // Adjust the value to match the sidebar width
  marginRight: 0,
  paddingLeft: "1.25rem",
  paddingRight: "1.25rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: 0,
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
}));

const DashboardLayout: FC = ({ children }) => {
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  return (
    <>
      <ModalProvider>
        <DashboardSidebar
          showMobileSideBar={showMobileSideBar}
          closeMobileSideBar={() => setShowMobileSideBar(false)}
        />

        <Wrapper>
          <DashboardNavbar
            setShowMobileSideBar={() => setShowMobileSideBar((state) => !state)}
          />
          {children || <Outlet />}
        </Wrapper>
      </ModalProvider>
    </>
  );
};

export default DashboardLayout;
