import { Navigate } from "react-router-dom";
import RouteGuard from "../guards/RouteGuard";
import AuthGuard from "../components/authentication/AuthGuard";
import GuestGuard from "../components/authentication/GuestGuard";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { LoginPortal } from "../pages/Authentication/LoginPortal";

import { CLIENT_TYPE_GROUP, CLIENT_TYPE_INDIVIDUAL, CLIENT_TYPE_INSTITUTION } from "../constants/client";
import { LOAN_STATUS_ACTIVE, LOAN_STATUS_DISBURSEMENT, LOAN_STATUS_APPLICATIONS, LOAN_STATUS_CLOSED, LOAN_STATUS_DECLINED } from '../constants/loans';

import LazyLoader from "./routes-loader"
import { T_TYPE_DEPOSIT, T_TYPE_WITHDRAWAL } from "../constants/savings";
import ResetPassword from "../pages/Authentication/ResetPassword/ResetPassword";
import ForgotPassword from "../pages/Authentication/ForgotPassword/ForgotPassword";

const routes = [
    {
        path: "/",
        element: <Navigate to="dashboard" />,
    },
    {
        path: "login",
        element: (
            <GuestGuard>
                <LoginPortal />
            </GuestGuard>
        ),
    },
    {
        path: "register",
        element: (
            <GuestGuard>
                <LoginPortal />
            </GuestGuard>
        ),
    },
    {
        path: "forgot-password",
        element: (
            <GuestGuard>
                <LoginPortal />
            </GuestGuard>
        ),
    },
    {
        path: "reset-password",
        element: (
            <GuestGuard>
                <ResetPassword />
            </GuestGuard>
        ),
    },
    {
        path: "dashboard",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "",
                element: <LazyLoader.DashboardSaaS />,
            },
        ],
    },
    {
        path: "forbidden",
        element: <LazyLoader.Error403 />,
    },
    {
        path: "*",
        element: <LazyLoader.Error404 />,
    },

    {
        path: "clients",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "individual",
                element: <LazyLoader.Clients client_type={CLIENT_TYPE_INDIVIDUAL} title={"Individuals"} />,
            },
            {
                path: "institution",
                element: <LazyLoader.Clients client_type={CLIENT_TYPE_INSTITUTION} title={"Individuals"} />,
            },

            {
                path: "group",
                element: <LazyLoader.Clients client_type={CLIENT_TYPE_GROUP} title={"Individuals"} />,
            },
            {
                path: "create-client",
                element: <LazyLoader.ClientForm />,
            },
            {
                path: "client-profile/:clientId",
                element: <LazyLoader.ClientProfile />,
            },
        ],
    },

    {
        path: "loans",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "active",
                element: <LazyLoader.Loans loanstatus={LOAN_STATUS_ACTIVE} />,
                requiresAuth: true,
            },
            {
                path: "disbursement",
                element: <LazyLoader.Loans loanstatus={LOAN_STATUS_DISBURSEMENT} />,
                requiresAuth: true,
            },
            {
                path: "applications",
                element: <LazyLoader.Loans loanstatus={LOAN_STATUS_APPLICATIONS} />,
                requiresAuth: true,
            },
            {
                path: "closed",
                element: <LazyLoader.Loans loanstatus={LOAN_STATUS_CLOSED} />,
                requiresAuth: true,
            },
            {
                path: "declined",
                element: <LazyLoader.Loans loanstatus={LOAN_STATUS_DECLINED} />,
                requiresAuth: true,
            },
            {
                path: "calculator",
                element: <LazyLoader.LoanCalculator />,
                requiresAuth: true,
            },
            {
                path: "collateral",
                element: <LazyLoader.LoanCollateral />,
                requiresAuth: true,
            },
            {
                path: "loan-details/:loanId",
                element: <LazyLoader.LoanDetail />,
                requiresAuth: true,
            },
            {
                path: "create-loan",
                element: <LazyLoader.LoanForm />,
            },
        ],
    },

    {
        path: "savings",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "deposit",
                element: <LazyLoader.RegisterDeposit />,
                requiresAuth: true,
            },
            {
                path: "withdraw",
                element: <LazyLoader.RegisterWithdraw />,
                requiresAuth: true,
            },
            {
                path: "deposit-transactions",
                element: <LazyLoader.Transactions t_type={T_TYPE_DEPOSIT} />,
                requiresAuth: true,
            },
            {
                path: "withdraw-transactions",
                element: <LazyLoader.Transactions t_type={T_TYPE_WITHDRAWAL} />,
                requiresAuth: true,
            },
            {
                path: "transfers",
                element: <LazyLoader.Transfers />,
                requiresAuth: true,
            },
            {
                path: "fixed-deposit",
                element: <LazyLoader.DepositCalculator />,
                requiresAuth: true,
            },
        ],
    },

    {
        path: "shares",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "register",
                element: <LazyLoader.ShareRegister />,
                requiresAuth: true,
            },
            {
                path: "share-purchase",
                element: <LazyLoader.PurchaseShares />,
            },
            {
                path: "share-transfer",
                element: <LazyLoader.TransferShares />,
                requiresAuth: true,
            },
            {
                path: "purchase-transactions",
                element: <LazyLoader.SharePurchases />,
                requiresAuth: true,
            },
            {
                path: "transfer-transactions",
                element: <LazyLoader.ShareTransfers />,
                requiresAuth: true,
            },
        ],
    },

    {
        path: "accounting",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "accounting",
                element: <LazyLoader.Accounting />,
                requiresAuth: true,
            },
            {
                path: "transaction-accounts",
                element: <LazyLoader.TransactionAccounts />,
                requiresAuth: true,
            },
            {
                path: "cash-transfers",
                element: <LazyLoader.CashTransfers />,
                requiresAuth: true,
            },
        ],
    },

    {
        path: "sms-banking",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "/sms-banking",
                element: <LazyLoader.SMSBanking />,
                requiresAuth: true,
            },
        ],
    },

    {
        path: "mobile-money",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "transactions",
                element: <LazyLoader.MobileMoneyTransactions />,
                requiresAuth: true,
            },
            {
                path: "ussd-push",
                element: <LazyLoader.USSDPush />,
                requiresAuth: true,
            },
            {
                path: "wallet-status",
                element: <LazyLoader.WalletStatus />,
                requiresAuth: true,
            },
            {
                path: "logs",
                element: <LazyLoader.MobileMoneyLogs />,
                requiresAuth: true,
            },
        ],
    },

    {
        path: "agent-banking",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "collections",
                element: <LazyLoader.AgentCollections />,
                requiresAuth: true,
            },
            {
                path: "all-transactions",
                element: <LazyLoader.AllAgentTransactions />,
                requiresAuth: true,
            },
            {
                path: "performance-report",
                element: <LazyLoader.PerformanceReport />,
                requiresAuth: true,
            },
        ],
    },

    {
        path: "mobile-banking",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "subscriptions",
                element: <LazyLoader.Subscriptions />,
                requiresAuth: true,
            },
            {
                path: "membership-applications",
                element: <LazyLoader.MembershipApplications />,
                requiresAuth: true,
            },
            {
                path: "transaction-via-app",
                element: <LazyLoader.TransactionViaApp />,
                requiresAuth: true,
            },
            {
                path: "general-settings",
                element: <LazyLoader.GeneralSettings />,
                requiresAuth: true,
            },
            {
                path: "loan-applications",
                element: <LazyLoader.LoanApplications2 />,
                requiresAuth: true,
            },
        ],
    },

    {
        path: "data-importer",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "import-clients",
                element: <LazyLoader.ImportClients />,
                requiresAuth: true,
            },
            {
                path: "import-individual-record/:recordId",
                element: <LazyLoader.ImportIndividualClientsRecord/>,
            },
            {
                path: "import-institution-record/:recordId",
                element: <LazyLoader.ImportInstitutionClientsRecord/>,
            },
            {
                path: "import-group-record/:recordId",
                element: <LazyLoader.ImportGroupClientsRecord/>,
            },
            {
                path: "import-loans",
                element: <LazyLoader.ImportLoans />,
                requiresAuth: true,
            },
            {
                path: "import-loan-record/:recordId",
                element: <LazyLoader.ImportLoansRecord/>,
            },
            {
                path: "import-shares",
                element: <LazyLoader.ImportShares />,
                requiresAuth: true,
            },
            {
                path: "import-share-register-record/:recordId",
                element: <LazyLoader.ImportShareRegisterRecord/>,
            },
            {
                path: "import-share-purchases-record/:recordId",
                element: <LazyLoader.ImportSharePurchasesRecord/>,
            },
            {
                path: "import-share-transfers-record/:recordId",
                element: <LazyLoader.ImportShareTransfersRecord/>,
            },

            {
                path: "import-transactions",
                element: <LazyLoader.ImportTransactions />,
                requiresAuth: true,
            },
            {
                path: "import-transaction-record/:recordId",
                element: <LazyLoader.ImportTransactionsRecord/>,
            },
            {
                path: "import-chart-of-accounts",
                element: <LazyLoader.ImportChartOfAccount />,
                requiresAuth: true,
            },
        ],
    },

    {
        path: "reports",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "summary-report",
                element: <LazyLoader.SummaryReport />,
                requiresAuth: true,
            },
            {
                path: "balance-sheet",
                element: <LazyLoader.BalanceSheet />,
                requiresAuth: true,
            },
            {
                path: "trial-balance",
                element: <LazyLoader.TrialBalance />,
                requiresAuth: true,
            },
            {
                path: "income-statement",
                element: <LazyLoader.IncomeStatement />,
                requiresAuth: true,
            },
            {
                path: "ageing-report",
                element: <LazyLoader.AgeingReport />,
                requiresAuth: true,
            },
            {
                path: "loan-arrears-report",
                element: <LazyLoader.LoanArrearsReport />,
                requiresAuth: true,
            },
            {
                path: "loan-status-report",
                element: <LazyLoader.LoanStatusReport />,
                requiresAuth: true,
            },
            {
                path: "loan-disbursement-report",
                element: <LazyLoader.LoanDisbursementReport />,
                requiresAuth: true,
            },
            {
                path: "loan-repayment-report",
                element: <LazyLoader.LoanRepaymentReport />,
                requiresAuth: true,
            },
            {
                path: "portfolio-at-risk-report",
                element: <LazyLoader.PortfolioAtRiskReport />,
                requiresAuth: true,
            },
            {
                path: "fee-report",
                element: <LazyLoader.FeeReport />,
                requiresAuth: true,
            },
            {
                path: "client-report",
                element: <LazyLoader.ClientReport />,
                requiresAuth: true,
            },
            {
                path: "membership-schedule",
                element: <LazyLoader.MembershipSchedule />,
                requiresAuth: true,
            },
            {
                path: "expenses-report",
                element: <LazyLoader.ExpensesReport />,
                requiresAuth: true,
            },
            {
                path: "credit-officer-report",
                element: <LazyLoader.CreditOfficerReport />,
                requiresAuth: true,
            },
            {
                path: "savings-report",
                element: <LazyLoader.SavingsReport />,
                requiresAuth: true,
            },
            {
                path: "audit-trail",
                element: <LazyLoader.AuditTrail />,
                requiresAuth: true,
            },
        ],
    },

    {
        path: "admin",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "bank-settings",
                element: <LazyLoader.BankSettings />,
                requiresAuth: true,
            },
            {
                path: "branches",
                element: <LazyLoader.Branches />,
                requiresAuth: true,
            },
            {
                path: "create-role",
                element: <LazyLoader.CreateRole />,
                requiresAuth: true,
            },
            {
                path: "roles-and-permissions",
                element: <LazyLoader.RolesAndPermissions />,
                requiresAuth: true,
            },
            {
                path: "staff",
                element: <LazyLoader.Staff />,
                requiresAuth: true,
            },
            {
                path: "staff/create",
                element: <LazyLoader.StaffForm />,
                requiresAuth: true,
            },
            {
                path: "create/staff",
                element: <LazyLoader.AddStaffForm />,
                requiresAuth: true,
            },
            {
                path: "staff/details/:staffId",
                element: <LazyLoader.StaffDetails />,
                requiresAuth: true,
            },
            {
                path: "fees-settings",
                element: <LazyLoader.FeesSettings />,
                requiresAuth: true,
            },
            {
                path: "loan-settings",
                element: <LazyLoader.LoanSettings />,
                requiresAuth: true,
            },
            {
                path: "savings-settings",
                element: <LazyLoader.SavingsSettings />,
                requiresAuth: true,
            },
            {
                path: "shares-settings",
                element: <LazyLoader.SharesSettings />,
                requiresAuth: true,
            },
            {
                path: "working-hours",
                element: <LazyLoader.WorkingHours />,
                requiresAuth: true,
            },
        ],
    },

    {
        path: "all-banks",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "/all-banks",
                element: (
                    <RouteGuard requiredPermissions={[]}>
                        <LazyLoader.Institution />
                    </RouteGuard>
                ),
                requiresAuth: true,
            },
        ],
    },

    {
        path: "all-banks-admin",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "/all-banks-admin",
                element: <LazyLoader.InstitutionAdmin />,
                requiresAuth: true,
            },
        ],
    },
    {
        path: "account",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "profile",
                element: <LazyLoader.UserProfile />,
                requiresAuth: true,
            },
        ],
    }
];

export default routes;