import instance from "../../../../api";

export const PasswordResetService = {

  //takes in email and sends a password reset link to the email. input = {"email": "string"}
  async passwordReset(payload: Object) {
    console.log(payload);
    return instance.post("password_reset/", payload).then((response) => response.data);
  },

  //takes in token and validates it. input = {"token": "string"}
  async validatePasswordResetToken(payload: Object) {
    console.log(payload);
    return instance.post(`password_reset/validate_token/`, payload).then((response) => response.data);
  },

  //takes in token and new password and resets the password. input =  {"password": "string","token": "string"}
  async confirmPasswordReset(payload: Object) {
    console.log(payload);
    return instance.post(`password_reset/confirm/`, payload).then((response) => response.data);
  },
};
