import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemButtonProps,
  Theme,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScrollBar from "simplebar-react";
import topMenuList, { IMenuItem } from "./MenuList";
import { H3, Small } from "../../components/Typography";
import UkoAvatar from "../../components/UkoAvatar";
import useAuth from "../../hooks/useAuth";
import FlexBox from "../../components/FlexBox";
// root component interface
interface SideNavBarProps {
  showMobileSideBar: boolean;
  closeMobileSideBar: () => void;
}

interface SubMenuItemProps extends ListItemButtonProps {
  isactive?: string; 
}

// custom styled components
const MainMenu = styled(Box)(({ theme }) => ({
  width: "18%", // Increased the width of the sidebar  // Adjust the value to match the width set in the Wrapper component
  height: "100%",
  position: "fixed",
  boxShadow: theme.shadows[2],
  transition: "width 0.3s ease",
  zIndex: theme.zIndex.drawer + 11,
  backgroundColor: "#003399", // tukole
  [theme.breakpoints.down("md")]: { width: 0 },
  "& .simplebar-track.simplebar-vertical": { width: 7 },
  "& .simplebar-scrollbar:before": {
    background: "#fff", // Set the scrollbar thumb color to white
    //background: theme.palette.text.primary,
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginBottom: "1rem",
  justifyContent: "flex-start",
  "&:hover": {
    backgroundColor: "transparent",
    "& .MuiTypography-body2": {
      color: "#ffd700", // Change the text color to #ffd700 when hovered
    },
    "& .expand-arrow": {
      color: "#ffd700", // Change the arrow color to #ffd700 when hovered
    },
  },
  "& .MuiTypography-body2": {
    color: "#fff",
    fontWeight: "bold",
  },
  "& .MuiSvgIcon-root": {
    color: "#fff",
    marginRight: theme.spacing(1),
  },
  "& .expand-arrow": {
    color: "#fff",
    marginLeft: "auto",
    marginRight: theme.spacing(1),
    transform: "rotate(90deg)",
  },
}));

const SubMenuItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "isactive",
})<SubMenuItemProps>(({ theme, isactive }) => ({
  paddingLeft: theme.spacing(4),
  "&:hover": { backgroundColor: "transparent" },
  "& .MuiTypography-body2": {
    color: isactive ? "#ffd700" : "#fff", // Apply golden color if isActive is true, otherwise white
    //color: "#fff",

    paddingLeft: theme.spacing(1), // Add left padding for the "dash" effect
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)",
      width: 2,
      height: 2,
      backgroundColor: isactive ? "#ffd700" : "#fff", // Apply golden color if isActive is true, otherwise white
      //backgroundColor: "#fff",
    },
  },
}));

const ExpandArrowIcon = styled("span")(({ theme }) => ({
  marginLeft: "auto",
  marginRight: theme.spacing(1),
  transform: "rotate(90deg)",
  color: "#fff", // Set the arrow color to white
}));

const ContentWrapper = styled(FlexBox)(() => ({
  top: 10,
  left: 10,
  right: 0,
  marginBottom: 10,
  width: "100%",
  alignItems: "center",
  position: "relative",
}));

// root component
const DashboardSideBar: FC<SideNavBarProps> = ({
  showMobileSideBar,
  closeMobileSideBar,
}) => {
  const navigate = useNavigate();
  const { user }: any = useAuth();

  const [activeMainMenu, setActiveMainMenu] = useState<string | null>(null);
  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null); // New state to keep track of the active submenu
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const isRightUser = (requiredUsers?: string[]) => {
    if (!requiredUsers) return true;
    return requiredUsers.includes(user?.type);
  };

  const handleActiveMainMenu = (menuItem: any) => () => {
    if (menuItem.subMenu) {
      setActiveMainMenu(
        activeMainMenu === menuItem.title ? null : menuItem.title
      );
    } else {
      setActiveMainMenu(null);
      setActiveSubMenu(null); // Close the active submenu when clicking a main menu item
      navigate(menuItem.path);
      closeMobileSideBar();
    }
  };

  const handleActiveSubMenu = (menuItem: any) => () => {
    setActiveSubMenu(menuItem.title); // Set the active submenu without affecting the main menu state
    navigate(menuItem.path);
  };

  // main menus content
  const mainSideBarContent = (
    <ScrollBar style={{ maxHeight: "calc(100% - 50px)" }}>
      <ContentWrapper>
        <UkoAvatar
          src={user?.avatar || "/static/avatar/001-man.svg"}
          sx={{
            border: 4,
            width: 70, //100
            height: 70, //100
            borderColor: "background.paper",
          }}
        />

        <Box marginLeft={3} marginTop={3} marginRight={2}>
          <H3 lineHeight={1.2}>
            {user?.first_name} {user?.last_name}
          </H3>
          <Small color="#ffff">
            {user?.first_name} {user?.last_name}
          </Small>
          {/*<Small color="#ffff">{"John"} {"Doe"}</Small>*/}
          <br />
          <Small color="#ffff">{user?.extra_details?.positiontitle}</Small>
          {/*<Small color="#ffff">{"Everpesa SACCO"} {"CEO"}</Small>*/}
        </Box>
      </ContentWrapper>
      <Box
        sx={{
          // Add the border to the bottom of the sidebar
          borderBottom: "3px solid #F3F4F9", // Customize the border style and color as needed
          width: "100%",
          marginTop: 3,
          alignSelf: "center",
        }}
      />
      <List sx={{ height: "100%" }}>
        {topMenuList.map((item: IMenuItem, index: number) => (
          <div key={index} hidden={!isRightUser(item.users)}>
            <Tooltip title={item.title} placement="right">
              <StyledListItemButton
                disableRipple
                onClick={handleActiveMainMenu(item)}
              >
                {item.Icon && <item.Icon />}
                <Typography variant="body2">{item.title}</Typography>
                {item.subMenu && <ExpandArrowIcon>&#8250;</ExpandArrowIcon>}
              </StyledListItemButton>
            </Tooltip>
            {activeMainMenu === item.title && item.subMenu && (
              <List>
                {item.subMenu.map((subItem: any, subIndex: number) => (
                  <Tooltip
                    title={subItem.title}
                    placement="right"
                    key={subIndex}
                  >
                    <SubMenuItem
                      disableRipple
                      onClick={handleActiveSubMenu(subItem)}
                      isactive={
                        activeSubMenu === subItem.title ? "true" : undefined
                      }
                      //isactive={activeSubMenu === subItem.title} // Add the isActive prop to the SubMenuItem component
                    >
                      {subItem.Icon && <subItem.Icon />}
                      <Typography variant="body2">{subItem.title}</Typography>
                    </SubMenuItem>
                  </Tooltip>
                ))}
              </List>
            )}
          </div>
        ))}
      </List>
    </ScrollBar>
  );

  // for mobile device
  if (downMd) {
    return (
      <Drawer
        anchor="left"
        open={showMobileSideBar}
        onClose={closeMobileSideBar}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "300px", // Set the maximum width for the mobile sidebar
            backgroundColor: "#003399", // Set the background color to #44814E
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            //width: "inherit",
            //position: "fixed",
            //overflow: "hidden",
            flexDirection: "column",
            boxShadow: (theme) => theme.shadows[1],
            backgroundColor: "#003399", // Set the background color to #44814E
            //backgroundColor: (theme) => theme.palette.background.paper,
            //"& .simplebar-track.simplebar-vertical": { width: 7 },
            //"& .simplebar-scrollbar:before": {
            //background: (theme) => theme.palette.text.primary,
            //},
          }}
        >
          {mainSideBarContent}
        </Box>
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor={downMd ? "left" : "left"}
      open={showMobileSideBar}
      onClose={closeMobileSideBar}
      variant={downMd ? "temporary" : "permanent"}
    >
      <MainMenu>{mainSideBarContent}</MainMenu>
    </Drawer>
  );
};

export default DashboardSideBar;
