import mFPassword from "./login/forgot-password.svg";
import mProfile from "./login/profile.svg";
import mCar from "./login/car-finance-rafiki.svg";
import mWallet from "./login/e-wallet-rafiki.svg";
import mFinance from "./login/finance-rafiki.svg";
import mSaving from "./login/saving-money.svg";

// Login Slider
export const ProfileSVG = mProfile;
export const FPassword = mFPassword;
export const Car = mCar;
export const Wallet = mWallet;
export const Finance = mFinance;
export const Saving = mSaving;

