import { USER_TYPES } from "../../constants/user-types";
import Icons from "../../icons/sidebar";

export interface IMenuItem {
  title: string;
  Icon?: any;
  path: string;
  subMenu?: IMenuItem[];
  permissions?: string[];
  users?: string[];
}

const MenuList: IMenuItem[] = [
  {
    title: "Dashboard",
    Icon: Icons.SpeedIcon,
    path: "/dashboard",
    // users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF, USER_TYPES.SYSTEM_USER],
  },

  {
    title: "Clients",
    Icon: Icons.GroupsIcon,
    path: "/clients",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/clients/individual",
        title: "Individual",
      },
      {
        path: "/clients/institution",
        title: "Institution",
      },
      {
        path: "/clients/group",
        title: "Group",
      },
    ],
  },

  {
    title: "Loans",
    Icon: Icons.PaidIcon,
    path: "/loans",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/loans/applications",
        title: "Applications",
      },
      {
        path: "/loans/disbursement",
        title: "Pending Disbursement",
      },
      {
        path: "/loans/active",
        title: "Active Loans",
      },
      {
        path: "/loans/declined",
        title: "Declined",
      },
      {
        path: "/loans/closed",
        title: "Closed",
      },
      {
        path: "/loans/calculator",
        title: "Loan Calculator",
      },
      {
        path: "/loans/collateral",
        title: "Collateral  Register",
      },
    ],
  },

  {
    title: "Savings",
    Icon: Icons.SavingsIcon,
    path: "/savings",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/savings/deposit",
        title: "Register Deposit",
      },
      {
        path: "/savings/withdraw",
        title: "Register Withdraw",
      },
      {
        path: "/savings/deposit-transactions",
        title: "Deposit Transactions",
      },
      {
        path: "/savings/withdraw-transactions",
        title: "Withdraw Transactions",
      },
      {
        path: "/savings/fixed-deposit",
        title: "Fixed Deposit Calculator",
      },
    ],
  },

  {
    title: "Shares",
    Icon: Icons.BalanceIcon,
    path: "/shares",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/shares/register",
        title: "Shares Register",
      },
      {
        path: "/shares/share-purchase",
        title: "Purchase Shares",
      },
      {
        path: "/shares/share-transfer",
        title: "Transfer Shares",
      },
      {
        path: "/shares/purchase-transactions",
        title: "Share Purchases",
      },
      {
        path: "/shares/transfer-transactions",
        title: "Share Transfers",
      },
    ],
  },

  {
    title: "Accounting",
    Icon: Icons.CalculateIcon,
    path: "/accounting",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/accounting/accounting",
        title: "Accounting",
      },
      {
        path: "/accounting/transaction-accounts",
        title: "Transaction Accounts",
      },
      {
        path: "/accounting/cash-transfers",
        title: "Cash Transfers",
      },
    ],
  },

  {
    title: "SMS Banking",
    Icon: Icons.SmsIcon,
    path: "/sms-banking",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
  },

  {
    title: "Mobile Money",
    Icon: Icons.PhoneAndroidIcon,
    path: "/mobile-money",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/mobile-money/transactions",
        title: "Mobile Money Transactions",
      },
      {
        path: "/mobile-money/ussd-push",
        title: "USSD Push",
      },
      {
        path: "/mobile-money/wallet-status",
        title: "Wallet Status",
      },
      {
        path: "/mobile-money/logs",
        title: "Mobile Money Logs",
      },
    ],
  },

  {
    title: "Agent Banking",
    Icon: Icons.SupportAgentIcon,
    path: "/agent-banking",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/agent-banking/collections",
        title: "Agent Collections",
      },
      {
        path: "/agent-banking/all-transactions",
        title: "All Agents Transactions",
      },
      {
        path: "/agent-banking/performance-report",
        title: "Performance Report",
      },
    ],
  },

  {
    title: "Mobile Banking",
    Icon: Icons.AccountBalanceWalletIcon,
    path: "/mobile-banking",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/mobile-banking/subscriptions",
        title: "Subscriptions",
      },
      {
        path: "/mobile-banking/membership-applications",
        title: "Membership Applications",
      },
      {
        path: "/mobile-banking/transaction-via-app",
        title: "Transactions Via App",
      },
      {
        path: "/mobile-banking/general-settings",
        title: "General Settings",
      },
      {
        path: "/mobile-banking/loan-applications",
        title: "Loan Applications",
      },
    ],
  },

  {
    title: "Data Importer",
    Icon: Icons.DriveFolderUploadIcon,
    path: "/data-importer",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/data-importer/import-clients",
        title: "Import Clients",
      },
      {
        path: "/data-importer/import-loans",
        title: "Import Loans",
      },
      {
        path: "/data-importer/import-shares",
        title: "Import Shares",
      },
      {
        path: "/data-importer/import-transactions",
        title: "Import Transactions",
      },
      {
        path: "/data-importer/import-chart-of-accounts",
        title: "Import Chart Of Accounts & Trial Balance",
      },
    ],
  },

  {
    title: "Reports",
    Icon: Icons.StackedLineChartIcon,
    path: "/reports",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/reports/summary-report",
        title: "Summary Report",
      },
      {
        path: "/reports/balance-sheet",
        title: "Balance Sheet",
      },
      {
        path: "/reports/trial-balance",
        title: "Trial Balance",
      },
      {
        path: "/reports/income-statement",
        title: "Income Statement",
      },
      {
        path: "/reports/ageing-report",
        title: "Ageing Report",
      },
      {
        path: "/reports/loan-arrears-report",
        title: "Loan Arrears Report",
      },
      {
        path: "/reports/loan-status-report",
        title: "Loan Status Report",
      },
      {
        path: "/reports/loan-disbursement-report",
        title: "Loan Disbursement Report",
      },
      {
        path: "/reports/loan-repayment-report",
        title: "Loan Repayment Report",
      },
      {
        path: "/reports/portfolio-at-risk-report",
        title: "Portfolio At Risk Report",
      },
      {
        path: "/reports/fee-report",
        title: "Fee Report",
      },
      {
        path: "/reports/client-report",
        title: "Client Report",
      },
      {
        path: "/reports/membership-schedule",
        title: "Membership Schedule",
      },
      {
        path: "/reports/expenses-report",
        title: "Expenses Report",
      },
      {
        path: "/reports/credit-officer-report",
        title: "Credit Officer Report",
      },
      {
        path: "/reports/savings-report",
        title: "Savings Report",
      },
      {
        path: "/reports/audit-trail",
        title: "Audit Trail",
      },
    ],
  },

  {
    title: "Admin",
    Icon: Icons.AdminPanelSettingsIcon,
    path: "/admin",
    //users: [USER_TYPES.INSTITUTION_ADMIN, USER_TYPES.STAFF],
    subMenu: [
      {
        path: "/admin/bank-settings",
        title: "Bank Settings",
      },
      {
        path: "/admin/branches",
        title: "Branches",
      },
      {
        path: "/admin/roles-and-permissions",
        title: "Roles & Permissions",
      },
      {
        path: "/admin/staff",
        title: "Staff",
      },
      {
        path: "/admin/fees-settings",
        title: "Fees Settings",
      },
      {
        path: "/admin/loan-settings",
        title: "Loan Settings",
      },
      {
        path: "/admin/savings-settings",
        title: "Savings Settings",
      },
      {
        path: "/admin/shares-settings",
        title: "Shares Settings",
      },
      {
        path: "/admin/working-hours",
        title: "Working Hours",
      },
    ],
  },

  // SYSTEM USER ONLY
  // {
  //   title: "Institutions",
  //   Icon: Icons.AccountBalanceIcon,
  //   path: "/all-banks",
  //   //users: [USER_TYPES.SYSTEM_USER]
  // },
  // {
  //   title: "Institution Admins",
  //   Icon: Icons.SupervisorAccountIcon,
  //   path: "/all-banks-admin",
  //   //users: [USER_TYPES.SYSTEM_USER]
  // },
  //ADMIN ONLY UP
  // {
  //   title: "Staff Details",
  //   Icon: Icons.UserProfileIcon,
  //   path: "/admin/staff/details",
  // },
  // {
  //   title: "User Grid",
  //   Icon: Icons.UserGridIcon,
  //   path: "/dashboard/user-grid",
  // },
  // {
  //   title: "User List",
  //   Icon: Icons.UserManagementIcon,
  //   path: "/dashboard/user-list",
  // },
  // {
  //   title: "Add user",
  //   Icon: Icons.AddUserIcon,
  //   path: "/dashboard/add-user",
  // },
];

export default MenuList;
