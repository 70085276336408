import { lazy, Suspense as ReactSuspense } from "react";
import LoadingScreen from "../components/LoadingScreen";

const Suspense: any = ReactSuspense;

const Loadable = (Component: any) => (props: any) => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );
};

const RouteLazyLoader = {
    // AUTHENTICATION ROUTES
    Login: Loadable(
        lazy(() => import("../pages/Authentication/Login/Login"))
    ),
    ForgotPassword: Loadable(
        lazy(() => import("../pages/Authentication/ForgotPassword/ForgotPassword"))
    ),
    ResetPassword: Loadable(
        lazy(() => import("../pages/Authentication/ResetPassword/ResetPassword"))
    ),
    //ResetPassword: Loadable(
    //    lazy(() => import("../pages/Authentication/ResetPassword"))
    //),

    // DASHBOARD ROUTES
    DashboardSaaS: Loadable(
        lazy(() => import("../pages/dashboards/SaaS"))
    ),

    // USER MANAGEMENT ROUTES
    UserProfile: Loadable(
        lazy(() => import("../pages/UserManagement/UserProfile"))
    ),

    // CLIENT ROUTES
    Clients: Loadable(
        lazy(() => import("../pages/Clients/Clients"))
    ),
    ClientForm: Loadable(
        lazy(() => import("../pages/Clients/ClientsForm"))
    ),
    ClientProfile: Loadable(
        lazy(() => import("../pages/Clients/ClientProfile"))
    ),

    // LOAN ROUTES
    Loans: Loadable(
        lazy(() => import("../pages/Loans/Loans"))
    ),
    LoanDetail: Loadable(
        lazy(() => import("../pages/Loans/LoanDetails"))
    ),
    LoanForm: Loadable(
        lazy(() => import("../pages/Loans/LoansForm"))
    ),
    LoanCollateral: Loadable(
        lazy(() => import("../pages/Loans/CollateralRegister/Collateral"))
    ),
    LoanCalculator: Loadable(
        lazy(() => import("../pages/Loans/LoansCalculator"))
    ),

    // SAVINGS ROUTES
    RegisterDeposit: Loadable(
        lazy(() => import("../pages/Savings/Deposit/RegisterDeposit"))
    ),
    RegisterWithdraw: Loadable(
        lazy(() => import("../pages/Savings/Withdraw/RegisterWithdraw"))
    ),
    Transactions: Loadable(
        lazy(() => import("../pages/Savings/Transactions/Transactions"))
    ),
    Transfers: Loadable(
        lazy(() => import("../pages/Savings/Transfers/Transfers"))
    ),
    DepositCalculator: Loadable(
        lazy(() => import("../pages/Savings/DepositCalculator/DepositCalculator"))
    ),

    // SHARES ROUTES
    ShareRegister: Loadable(
        lazy(() => import("../pages/Shares/ShareRegister/ShareRegister"))
    ),
    PurchaseShares: Loadable(
        lazy(() => import("../pages/Shares/PurchaseShares/PurchaseShares"))
    ),
    TransferShares: Loadable(
        lazy(() => import("../pages/Shares/TransferShares/TransferShares"))
    ),
    SharePurchases: Loadable(
        lazy(() => import("../pages/Shares/SharePurchases/PurchaseTransactions"))
    ),
    ShareTransfers: Loadable(
        lazy(() => import("../pages/Shares/ShareTransfers/TransferTransactions"))
    ),

    // ACCOUNTING ROUTES
    Accounting: Loadable(
        lazy(() => import("../pages/Accounting/CoreAccounting/CoreAccounting"))
    ),
    TransactionAccounts: Loadable(
        lazy(() => import("../pages/Accounting/TransactionAccounts/TransactionAccounts"))
    ),
    CashTransfers: Loadable(
        lazy(() => import("../pages/Accounting/CashTransfers/CashTransfers"))
    ),


    // SMS BANKING ROUTES
    SMSBanking: Loadable(
        lazy(() => import("../pages/SMSBanking/SMSBanking"))
    ),

    // MOBILE MONEY ROUTES
    MobileMoneyTransactions: Loadable(
        lazy(() => import("../pages/MobileMoney/MobileMoneyTransactions"))
    ),
    USSDPush: Loadable(
        lazy(() => import("../pages/MobileMoney/USSDPush"))
    ),
    WalletStatus: Loadable(
        lazy(() => import("../pages/MobileMoney/WalletStatus"))
    ),
    MobileMoneyLogs: Loadable(
        lazy(() => import("../pages/MobileMoney/MobileMoneyLogs"))
    ),

    // AGENT BANKING ROUTES
    AgentCollections: Loadable(
        lazy(() => import("../pages/AgentBanking/AgentCollections"))
    ),
    AllAgentTransactions: Loadable(
        lazy(() => import("../pages/AgentBanking/AllAgentTransactions"))
    ),
    PerformanceReport: Loadable(
        lazy(() => import("../pages/AgentBanking/PerformanceReport"))
    ),

    // MOBILE BANKING ROUTES
    Subscriptions: Loadable(
        lazy(() => import("../pages/MobileBanking/Subscriptions"))
    ),
    MembershipApplications: Loadable(
        lazy(() => import("../pages/MobileBanking/MembershipApplications"))
    ),
    TransactionViaApp: Loadable(
        lazy(() => import("../pages/MobileBanking/TransactionViaApp"))
    ),
    GeneralSettings: Loadable(
        lazy(() => import("../pages/MobileBanking/GeneralSettings"))
    ),
    LoanApplications2: Loadable(
        lazy(() => import("../pages/MobileBanking/LoanApplications2"))
    ),

    // DATA IMPORTER ROUTES
    ImportClients: Loadable(
        lazy(() => import("../pages/DataImporter/ImportClients/ImportClients"))
    ),
    ImportIndividualClientsRecord: Loadable(
        lazy(() => import("../pages/DataImporter/ImportClients/IndividualClients/IndividualClientsRecords"))
    ),
    ImportInstitutionClientsRecord: Loadable(
        lazy(() => import("../pages/DataImporter/ImportClients/InstitutionClients/InstitutionClientsRecords"))
    ),
    ImportGroupClientsRecord: Loadable(
        lazy(() => import("../pages/DataImporter/ImportClients/GroupClients/GroupClientsRecords"))
    ),

    ImportLoans: Loadable(
        lazy(() => import("../pages/DataImporter/ImportLoans/ImportLoans"))
    ),
    ImportLoansRecord: Loadable(
        lazy(() => import("../pages/DataImporter/ImportLoans/ImportLoansRecords"))
    ),
    ImportShares: Loadable(
        lazy(() => import("../pages/DataImporter/ImportShares/ImportShares"))
    ),
    ImportShareRegisterRecord: Loadable(
        lazy(() => import("../pages/DataImporter/ImportShares/ShareRegister/ShareRegisterRecords"))
    ),
    ImportSharePurchasesRecord: Loadable(
        lazy(() => import("../pages/DataImporter/ImportShares/SharePurchases/SharePurchasesRecords"))
    ),
    ImportShareTransfersRecord: Loadable(
        lazy(() => import("../pages/DataImporter/ImportShares/ShareTransfers/ShareTransfersRecords"))
    ),
    ImportTransactions: Loadable(
        lazy(() => import("../pages/DataImporter/ImportTransactions/ImportTransactions"))
    ),
    ImportTransactionsRecord: Loadable(
        lazy(() => import("../pages/DataImporter/ImportTransactions/ImportTransactionsRecords"))
    ),
    ImportChartOfAccount: Loadable(
        lazy(() => import("../pages/DataImporter/ImportChartOfAccounts/ImportChartOfAccounts"))
    ),

    // REPORTS ROUTES
    SummaryReport: Loadable(
        lazy(() => import("../pages/Reports/SummaryReport/SummaryReport"))
    ),
    BalanceSheet: Loadable(
        lazy(() => import("../pages/Reports/BalanceSheet/BalanceSheet"))
    ),
    TrialBalance: Loadable(
        lazy(() => import("../pages/Reports/TrialBalance/TrialBalance"))
    ),
    IncomeStatement: Loadable(
        lazy(() => import("../pages/Reports/IncomeStatement/IncomeStatement"))
    ),
    AgeingReport: Loadable(lazy(() => import("../pages/Reports/AgeingReport/AgeingReport"))),
    LoanArrearsReport: Loadable(
        lazy(() => import("../pages/Reports/LoanArrearsReport/LoanArrearsReport"))
    ),
    LoanStatusReport: Loadable(
        lazy(() => import("../pages/Reports/LoanStatusReport/LoanStatusReport"))
    ),
    LoanDisbursementReport: Loadable(
        lazy(() => import("../pages/Reports/LoanDisbursementReport/LoanDisbursementReport"))
    ),
    LoanRepaymentReport: Loadable(
        lazy(() => import("../pages/Reports/LoanRepaymentReport/LoanRepaymentReport"))
    ),
    PortfolioAtRiskReport: Loadable(
        lazy(() => import("../pages/Reports/PortfolioAtRiskReport/PortfolioAtRiskReport"))
    ),
    FeeReport : Loadable(
        lazy(() => import("../pages/Reports/FeeReport/FeeReport"))
    ),
    ClientReport: Loadable(
        lazy(() => import("../pages/Reports/ClientReport/ClientReport"))
    ),
    MembershipSchedule: Loadable(
        lazy(() => import("../pages/Reports/MembershipSchedule/MembershipSchedule"))
    ),
    ExpensesReport: Loadable(
        lazy(() => import("../pages/Reports/ExpensesReport/ExpensesReport"))
    ),
    CreditOfficerReport: Loadable(
        lazy(() => import("../pages/Reports/CreditOfficerReport/CreditOfficerReport"))
    ),
    SavingsReport: Loadable(
        lazy(() => import("../pages/Reports/SavingsReport/SavingsReport"))
    ),
    AuditTrail: Loadable(
        lazy(() => import("../pages/Reports/AuditTrail/AuditTrail"))
    ),

    // INSTUTION ADMIN ROUTES
    BankSettings: Loadable(
        lazy(() => import("../pages/Admin/BankSettings/BankSettings"))
    ),
    Branches: Loadable(
        lazy(() => import("../pages/Admin/Branch/Branch"))
    ),
    CreateRole: Loadable(
        lazy(() => import("../pages/Admin/RoleAndPersmissions/AddRoles"))
    ),
    RolesAndPermissions: Loadable(
        lazy(() => import("../pages/Admin/RoleAndPersmissions/RolesAndPermissions"))
    ),
    Staff: Loadable(
        lazy(() => import("../pages/Admin/Staff/Staff"))
    ),
    StaffForm: Loadable(
        lazy(() => import("../pages/Admin/Staff/StaffForm"))
    ),
    AddStaffForm: Loadable(
        lazy(() => import("../pages/Admin/StaffManagement/AddStaffForm"))
    ),
    StaffDetails: Loadable(
        lazy(() => import("../pages/Admin/Staff/StaffDetail"))
    ),
    FeesSettings: Loadable(
        lazy(() => import("../pages/Admin/FeesSettings/FeesSettings"))
    ),
    LoanSettings: Loadable(
        lazy(() => import("../pages/Admin/LoanSettings/LoanSettings"))
    ),
    SavingsSettings: Loadable(
        lazy(() => import("../pages/Admin/SavingSettings/SavingSettings"))
    ),
    SharesSettings: Loadable(
        lazy(() => import("../pages/Admin/ShareSettings/SharesSettings"))
    ),
    WorkingHours: Loadable(
        lazy(() => import("../pages/Admin/WorkingHours/WorkingHours"))
    ),

    // everpesa ADMIN ROUTES
    Institution: Loadable(
        lazy(() => import("../pages/Institution/Institution"))
    ),
    InstitutionAdmin: Loadable(
        lazy(() => import("../pages/InstitutionAdmin/InstitutionAdmin"))
    ),

    // ERROR ROUTES
    Error404: Loadable(lazy(() => import("../pages/404"))),
    Error403: Loadable(lazy(() => import("../pages/403"))),
}

export default RouteLazyLoader;